export const environment = {
  production: false,

  // baseUrl: 'https://vicasapi.livnsense.com/',
  // baseUrl: 'https://api.qa.austin.vicas.livnsense.com/',
  // baseUrl: 'https://api.vicasserver.vicasdevp2.livnsense.com/',
  baseUrl: 'https://api.vicasserver.vicas.isense4i.com/',
  // baseUrl: 'https://api.dev.vicas.livnsense.com/',

  apiInterval: 2000,
  roles: ['Admin', 'Owner', 'Building Manager', 'Super User', 'Engineer'],

  // nav: [{ name: 'Dashboard',  route: '/dashboard' },
  //       { name: 'Analytics',  route: '/analytics/zone/337' }, 
  //       { name: 'Management',  route: '/manage' },
  //       // { name: 'Report', icon: 'fa fa-line-chart', route: '/report/zone/337'icon: 'fa fa-home',icon: 'fa fa-bar-chart',icon: 'fa fa-database',icon: 'fa fa-cogs', },
        
  //       // { name: 'Utilization', icon: 'fa fa-cogs', route: '/utilization/utilization-graph' }/zone/337, 
  //       { name: 'Configuration',  route: '/configuration' },
  //       // { name: 'Geofence', icon: 'fa fa-map', route: '/configuration/configure-geofence' },
  //     ],

  nav: [{ name: 'Dashboard', icon: 'fa fa-home', route: '/dashboard' }, 
  // { name: 'Report', icon: 'fa fa-exclamation-triangle', route: '/report/zone/337' },
  { name: 'Analytics', icon: 'fa fa-bar-chart', route: '/analytics/zone/337' },
  { name: 'Management', icon: 'fa fa-database', route: '/manage' },
  // { name: 'Utilization', icon: 'fa fa-cogs', route: '/utilization/utilization-graph' }, 
  { name: 'Configuration', icon: 'fa fa-line-chart', route: '/configuration' },
  // { name: 'Geofence', icon: 'fa fa-map', route: '/configuration/configure-geofence' }
],

  dropdownOptions: {
    genders: [{ name: 'Male', value: 'M' }, { name: 'Female', value: 'F' }, { name: 'Others', value: 'O' }],
    statuses: [{ name: 'Active', value: true }, { name: 'In Active', value: false }]
  },

}
