import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

const baseUrl = environment['baseUrl'];

@Injectable({
  providedIn: 'root'
})
export class ApiCallsService {

  constructor(private http: HttpClient) { }

  /* Method provides us the notification results */
  getNotification(){
    return this.http.get(`${baseUrl}incident/notification`)
    .pipe(map((response: any) => {
      return response
    }, (error) => {
      // console.log(error);
    }
    ));
  }
  /* Method provides us the notification results */
  removeNotification(){
    return this.http.post(`${baseUrl}incident/notification`,{count:0})
    .pipe(map((response: any) => {
      return response
    }, (error) => {
      // console.log(error);
    }
    ));
  }
  /* Method which makes API call to get the all vehicles */
  getAllVehicles(): Observable<any> {
    return this.http.get(`${baseUrl}vehicle/search`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }

   /* Method to get the incident image */
   getIncidentImage(imageName) {
    return this.http.get(`${baseUrl}incident/image?name=${imageName}&decoder=base`,
      {
        responseType: 'text' // change responseType to arraybuffer, once we do arraybuffer
      }).pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            // console.log(error);
          }
        )
      );
  }
   /* Method to get the incident image */
   getIncidentVideo(url) {
    return this.http.get(url,
      {
        responseType: 'blob' // change responseType to arraybuffer, once we do arraybuffer
      }).pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            // console.log(error);
          }
        )
      );
  }
  fetchIncidentsRecords(query, lower, upper, type?: any): Observable<any> {

    const t = typeof type !== 'undefined' ? `&incident_type=${type}` : '';

    return this.http.get(`${baseUrl}proximity?${query}&lower_lmt=${lower}&upper_lmt=${upper}${t}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        console.log(error);
      }
      ));
  }
  fetchIncidentsRecordsppe(query, lower, upper, type?: any): Observable<any> {

    const t = typeof type !== 'undefined' ? `&incident_type=${type}` : '';

    return this.http.get(`${baseUrl}safety/list?${query}&lower_lmt=${lower}&upper_lmt=${upper}${t}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        console.log(error);
      }
      ));
  }

  getIncidentsRecordsppe(query, lower, upper, type?: any): Observable<any> {

    const t = typeof type !== 'undefined' ? `&incident_type=${type}` : '';

    return this.http.get(`${baseUrl}safety/list?${query}&lower_lmt=${lower}&upper_lmt=${upper}${t}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }
  getLatestProxy(query, lower, upper,type?: any,free?:any): Observable<any>{
    const t = typeof type !== 'undefined' ? `&incident_type=${type}` : '';
    const f = typeof free !== 'undefined' ? `&free_number=${free}` : '';


    return this.http.get(`${baseUrl}proximity/latest?${query}&lower_lmt=${lower}&upper_lmt=${upper}${t}${f}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));

  }
  getIncidentsRecordsdata(query, lower, upper, type?: any): Observable<any> {

    const t = typeof type !== 'undefined' ? `&incident_type=${type}` : '';

    return this.http.get(`${baseUrl}proximity?${query}&lower_lmt=${lower}&upper_lmt=${upper}${t}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }b
  getIncidentsData(query): Observable<any> {
    return this.http.get(`${baseUrl}overview?${query}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }
  getIncidentsData1(query): Observable<any> {
    return this.http.get(`${baseUrl}overview?${query}&cam=FRONT_CENTER,BACK_CENTER,LEFT_CENTER,RIGHT_CENTER`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }
  elementRemover(arr){
    if (arr.indexOf('') > -1){arr.splice(arr.indexOf(''), 1);}
      if (arr.length == 0){
        
        return(null) 
      }else{
        return(arr)  
      }

    }
  IncidentUpdate(ticketstatus,ticketdesc,incidentid): Observable<any> {
    let body = {
      ticket_status:ticketstatus,
      ticket_desc:this.elementRemover(ticketdesc),
      incident_id:incidentid
      }
    return this.http.put(`${baseUrl}proximity/update`,body)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }
  IncidentUpdate2(proximity,ticketstatus,ticketdesc,incidentid): Observable<any> {
    let body = {
      proximity:proximity, 
      ticket_status:ticketstatus,
      ticket_desc:this.elementRemover(ticketdesc),
      incident_id:incidentid
      }
    return this.http.put(`${baseUrl}proximity/update`,body)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }
  getIncidentsRecords(query, lower, upper, type?: any): Observable<any> {
    const t = typeof type !== 'undefined' ? `&incident_type=${type}` : '';
    return this.http.get(`${baseUrl}safety/list?${query}&lower_lmt=${lower}&upper_lmt=${upper}${t}`)
      .pipe(map((response: any) => {
        return response
      }, (error) => {
        // console.log(error);
      }
      ));
  }
  IncidentUpdate1(ticketstatus,ticketdesc,incidentid): Observable<any> {

    let body = {

      ticket_status:ticketstatus,

      ticket_desc:this.elementRemover(ticketdesc),

      incident_id:incidentid

      }

    return this.http.put(`${baseUrl}safety/update`,body)

      .pipe(map((response: any) => {

        return response

      }, (error) => {

        // console.log(error);

      }

      ));

  }
}
