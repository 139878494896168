import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@app/routes/authentication/authentication.module').then(
        m => m.AuthenticationModule
      )
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@app/routes/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'safety-incidents',
    loadChildren: () =>
      import('@app/routes/safety-incidents/safety-incidents.module').then(
        m => m.SafetyIncidentsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'distance-incidents',
    loadChildren: () =>
      import('@app/routes/distance-incidents/distance-incidents.module').then(
        m => m.DistanceIncidentsModule
      ),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'cognitive-incidents',
  //   loadChildren: () =>
  //     import('@app/routes/cognitive-incidents/cognitive-incidents.module').then(
  //       m => m.CognitiveIncidentsModule
  //     ),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'manage-asset',
    loadChildren: () =>
      import('@app/routes/manage-asset/manage-asset.module').then(
        m => m.ManageAssetModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-user',
    loadChildren: () =>
      import('@app/routes/manage-user/manage-user.module').then(
        m => m.ManageUserModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-driver',
    loadChildren: () =>
      import('@app/routes/manage-driver/manage-driver.module').then(
        m => m.ManageDriverModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-vehicle',
    loadChildren: () =>
      import('@app/routes/manage-vehicle/manage-vehicle.module').then(
        m => m.ManageVehicleModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-zone',
    loadChildren: () =>
      import('@app/routes/manage-zone/manage-zone.module').then(
        m => m.ManageZoneModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-zone-site',
    loadChildren: () =>
      import('@app/routes/manage-zone-site/manage-zone-site.module').then(
        m => m.ManageZoneSiteModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-device',
    loadChildren: () =>
      import('@app/routes/manage-device/manage-device.module').then(
        m => m.ManageDeviceModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manage-workarea',
    loadChildren: () =>
      import('@app/routes/manage-workarea/manage-workarea.module').then(
        m => m.ManageWorkareaModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () =>
      import('@app/routes/configuration/configuration.module').then(
        m => m.ConfigurationModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'fleet-management',
    loadChildren: () =>
      import('@app/routes/fleet-management/fleet-management.module').then(
        m => m.FleetManagementModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    loadChildren: () =>
      import('@app/routes/report/report.module').then(
        m => m.ReportModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'utilization',
    loadChildren: () =>
      import('@app/routes/utilization/utilization.module').then(
        m => m.UtilizationModule
      ),
    canActivate: [AuthGuard]
  },
  {
  path: 'analytics',
    loadChildren: () =>
      import('@app/routes/analytics/analytics.module').then(
        m => m.AnalyticsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'auth'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
