import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { MatSidenavModule, MatIconModule, MatToolbarModule, MatMenuModule, MatListModule, MatRippleModule, MatButtonModule, MatTooltipModule,MatSnackBarModule } from '@angular/material';
import 'hammerjs';


/* HTTP Module and HTTP_Interceptors for making http calls */
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { AppRoutingModule } from '@app/app-routing.module';

import { TokenAuth } from '@app/core/auth/auth.interceptor';
import { AuthService } from '@app/core/auth/auth.service';
import {MatTableModule} from '@angular/material/table';

import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    MatTableModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    MatRippleModule,
    MatButtonModule,
    MatTooltipModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyC0_D5WKsaUUnYv9q2AIGwFykqHGVinIlI' + '&libraries=visualization',
      apiKey: 'AIzaSyBl0iyK10oDnAUJgfKnF00xSjFB4xkpiAQ' + '&libraries=visualization',
      libraries: ['visualization']
    })

  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenAuth,
    multi: true
  }, AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
