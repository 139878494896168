import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }

  /* Method which returns jwt token */
  public getToken(): string {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user'))['token'];
    }
  }

  /* Method to check token expiry */
  public isAuthenticated(): boolean {
    const token = this.getToken();

    // commented below as we didn't implement token validity
    // return token ? helper.isTokenExpired(token) : false;

    // remove below line if we implement token validity
    return token ? true : false;
  }

  /* Method returns decoded token */
  public getLoggedUserPermissions(): object {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);

    return decoded['permission'];
  }

  /* Method which returns the role of logged user */
  public getLoggedUserRole(): string {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);
    return decoded['role_id'];
  }

  /* Method which returns the name and role of logged user */
  public getLoggedUserInfo(): string {
    const token = this.getToken();

    const decoded = helper.decodeToken(token);
    return decoded;
  }

  getResetLoginId(token) {

    const decoded = helper.decodeToken(token);

    return decoded;
  }
}
