import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/*
 * animation: sideNaveAnimation
 * trigger: 'openClose'
 *
 * comments: sets the width of an element to 190px when 'open' and to 60px
 *   when closed.  Animates in between these two states over '0.3s'
 */

export const sideNavAnimation = trigger('openCloseSidenav', [
  state('open', style({
    width: '7%',
  })),
  state('closed', style({
    width: '0%',
  })),
  transition('open <=> closed', [
    animate('0.1s')
  ]),
]);

/*
 * animation: sideNavContainerAnimation
 * trigger: 'openCloseSidenavContent'
 *
 * comments: Sets the margin-left to 191px when "open" and 61px when "closed".
 */

export const sideNavContainerAnimation = trigger('openCloseSidenavContent', [
  state('open', style({
    'margin-left': '7.1%',
  })),
  state('closed', style({
    'margin-left': '0.1%',
  })),
  transition('open <=> closed', [
    animate('0.1s')
  ]),
]);