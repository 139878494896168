import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpXsrfTokenExtractor
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

/* Intercept an outgoing HttpRequest and optionally transform it or the response. */
@Injectable()
export class TokenAuth implements HttpInterceptor {
  constructor(
    readonly router: Router,
    private cookieExtractor: HttpXsrfTokenExtractor,
    public authService: AuthService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const token: string = this.authService.getToken();

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    const urlArray = request['url'].split('/');
    const isUploadAPI = urlArray[urlArray.length - 3] === 'upload' ? true : false;

    if (!isUploadAPI) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/json')
        });
      }
    }

    /*  request = request.clone({
       headers: request.headers.set('Accept', 'application/json')
     }); */

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.router.navigate(['auth/login']);
            return throwError(error);
          } else {
            return throwError(error);
          }
        }
      })
    );
  }
}
