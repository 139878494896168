import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

const baseUrl = environment['baseUrl'];

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  selectedDateFilter: any;

  selectedRole: any;

  qrData: any;

  vehicleToModify: any;

  userToModify: any;

  deviceToModify: any;

  zoneToModify: any;

  driverToModify: any;

  workareaToModify: any;

  incidentToApply: any;

  selectedVehicles: any = [];

  selectedCamera: any = [];
  date: any;

  incidentSelectedRef:any = false;
  static color: any;

  constructor(readonly http: HttpClient) { }
  
  /* Getter for incident list row selection, used for highlighting bg */
  get getIncidentRef(): any{
     return this.incidentSelectedRef;
  }

  /* Setter for incident list row selection, used for highlighting bg */
  set setIncidentRef(id) {
    this.incidentSelectedRef = id;
  }
  /* Getter for vehicles filter in reports */
  get getVehicle(): any {
    return this.selectedVehicles;
  }

  /* Setter for vehicles filter in reports */
  set setVehicle(filter) {
    this.selectedVehicles = filter;
  }
  /* Getter for camera filter in reports */
  get getCamera(): any {
    return this.selectedCamera;
  }

  /* Setter for camera filter in reports */
  set setCamera(filter) {
    this.selectedCamera = filter;
  }

  /* Getter for date filter in reports */
  get dateFilter(): any {
    return this.selectedDateFilter;
  }

  /* Setter for date filter in reports */
  set dateFilter(filter) {
    this.selectedDateFilter = filter;
  }

  /* Getter for selected role in assessment form */
  get role() {
    return this.selectedRole;
  }

  /* Setter for selected role in assessment form */
  set role(role) {
    this.selectedRole = role;
  }

  /* Getter for Safe Proximity Incidents */
  get incidentDetails() {
    return this.incidentToApply;
  }

  /* Setter for Safe Proximity Incidents */
  set incidentDetails(data) {
    this.incidentToApply = data;
  }

  /* Getter for selected Work Area in modify Work Area */
  get modifyWorkarea() {
    return this.workareaToModify;
  }

  /* Setter for selected Work Area in modify Work Area */
  set modifyWorkarea(data) {
    this.workareaToModify = data;
  }

  /* Getter for selected building in modify building */
  get modifyVehicle() {
    return this.vehicleToModify;
  }

  /* Setter for selected building in modify building */
  set modifyVehicle(data) {
    this.vehicleToModify = data;
  }

  /* Getter for selected building in modify building */
  get modifyDevice() {
    return this.deviceToModify;
  }

  /* Setter for selected building in modify building */
  set modifyDevice(data) {
    this.deviceToModify = data;
  }

  /* Getter for selected building in modify building */
  get modifyUser() {
    return this.userToModify;
  }

  /* Setter for selected building in modify building */
  set modifyUser(data) {
    this.userToModify = data;
  }

  /* Getter for selected building in modify building */
  get modifyZone() {
    return this.zoneToModify;
  }

  /* Setter for selected building in modify building */
  set modifyZone(data) {
    this.zoneToModify = data;
  }

  /* Getter for selected building in modify building */
  get modifyDriver() {
    return this.driverToModify;
  }

  /* Setter for selected building in modify building */
  set modifyDriver(data) {
    this.driverToModify = data;
  }

  /* Getter for selected role in assessment form */
  get qrdata() {
    return this.qrData;
  }

  /* Getter for selected role in assessment form */
  set qrdata(data) {
    this.qrData = data;
  }

  /* Method which makes API call to update ticket status */
  updateTicketStatus(body): Observable<any> {
    return this.http.put(`${baseUrl}ticket/modify`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        error => {
          // console.log(error);
        }
      )
    );
  }

  /* Method to get the incident image */
  getIncidentImage(imageName) {
    return this.http.get(`${baseUrl}incident/image?name=${imageName}&decoder=base`,
      {
        responseType: 'text' // change responseType to arraybuffer, once we do arraybuffer
      }).pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            // console.log(error);
          }
        )
      );
  }
  /* Method to get the incident image */
  getIncidentVideo(url) {
    return this.http.get(url,
      {
        responseType: 'blob' // change responseType to arraybuffer, once we do arraybuffer
      }).pipe(
        map(
          (response: any) => {
            return response;
          },
          error => {
            // console.log(error);
          }
        )
      );
  }

  // getIncidentVideo(videoName) {
  //   return this.http.get(`${baseUrl}incident/video?name=${"/opt/vicas/images/2/4/16482/4_1648274995648.mp4"}`, //"/opt/vicas/images/2/4/16482/4_1648274995648.mp4"
  //     { 
  //       responseType: 'text' // change responseType to arraybuffer, once we do arraybuffer
  //     }).pipe(
  //       map(
  //         (response: any) => {
  //           return response;
  //         },
  //         error => {
  //           // console.log(error); 
  //         }
  //       )
  //     );
  // }


  formAmchartData(data, keys, parameter) {

    const dataSet = data;

    const timestamps = [];


    // // console.log(this.selectedDateFilter)

    /* Push all the timeinstance array from all the data */

    keys.sort().forEach(e => {
      timestamps.push(dataSet[e]['timeinstance']);
    });

    /* Merge all the dates */
    const mergedArray = [].concat.apply([], timestamps);

    /* After merging get the unique dates */
    const uniqueDates = [...new Set(mergedArray)];

    let selectedEndDate = new Date(this.selectedDateFilter['end_date']);

    let redableEndDate = `${selectedEndDate.getFullYear()}-${selectedEndDate.getMonth() + 1}-${selectedEndDate.getDate()}`;

    if(!uniqueDates.includes(redableEndDate)){
        uniqueDates.push(redableEndDate);
    }
    /* Sort the dates in ascending order */
    uniqueDates.sort((a: Date, b: Date) => {

      if (new Date(a).getTime() < new Date(b).getTime()) {
        return -1;
      }
      if (new Date(a).getTime() > new Date(b).getTime()) {
        return 1;
      }
      return 0;
    });
    
    /* Sort the dates in ascending order */
    uniqueDates.sort((a: Date, b: Date) => {

      if (new Date(a).getTime() < new Date(b).getTime()) {
        return -1;
      }
      if (new Date(a).getTime() > new Date(b).getTime()) {
        return 1;
      }
      return 0;
    });
    // console.log(this.selectedDateFilter,uniqueDates)

    /* Iterate the sorted dates to form required JSON */
    let d = uniqueDates
      .map((e, i) => Object.assign({},
        ...keys.map((k) => {

          const index = dataSet[k]['timeinstance'].findIndex(el => el === e);

          return ({
            [k]: index > -1 ? dataSet[k][parameter][index] : 0,
            date: e
          })
        })
      )
      );
    return d;
  }

  /* method which returns date filters, converting them to epoch time */

  formDateQuery(filters) {
    const start = new Date(new Date(filters['start_date']).setHours(0, 0, 0, 0)).getTime();
    const end = new Date(new Date(filters['end_date']).setHours(23, 59, 59, 999)).getTime();

    return `start_date=${start}&end_date=${end}`;
  }

  isPageAuthenticated(screen, action) {
    const decodeUser = helper.decodeToken(JSON.parse(localStorage.getItem('user'))['token'])['permission'];
    if (decodeUser) {
      loop: for (let x = 0; x < decodeUser.length; x++) {
        for (const [key, value] of Object.entries(decodeUser[x])) {
          if (screen === key && decodeUser[x][key]['status']) {
            return decodeUser[x][key][action];
            break loop;
          }
        }
      }
    }
  }
}
