import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { sideNavAnimation, sideNavContainerAnimation } from './sidenav.animation';
import { MatSidenav } from '@angular/material';
import { UtilsService } from './core/utils.service';
import { AuthService } from './core/auth/auth.service';
import { environment } from '@env/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiCallsService } from './routes/dashboard/services/api-calls.service';
import {MatSnackBar,MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
  import { Observable, Subscription,timer } from 'rxjs';

const helper = new JwtHelperService();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [sideNavAnimation, sideNavContainerAnimation]
})

export class AppComponent implements OnInit {
  @ViewChild('snav', { static: false }) sidenav: MatSidenav;

  public navList: Array<object> = environment['nav'];

  mobileQuery: MediaQueryList;
  url: string;
  oUrl: string;
  userinfo: any;
  username: any;
  userrole: any;
  ManagementFlag: boolean = false;
  entity: any;
  date = new Date().getMilliseconds();
  isAlertDisplayed:boolean = false;
  isDatePickerOpened: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  refreshRate:number;
  timerSubscription: Subscription;
  bellNotification:String = '';
  currentYear: number = new Date().getFullYear();
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private utils: UtilsService,
    private authService: AuthService,
    private apiService:ApiCallsService,
    private _snackBar: MatSnackBar
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.getUrl();
        
        if(typeof this.entity == 'undefined' && localStorage.getItem('user') !== null) {
          this.entity =  helper.decodeToken(JSON.parse(localStorage.getItem('user'))['token']).entity_id;
        }
        
        // this.isUserAuthenticated();
      }
    });

  }

  subscribeToData() {
       this.timerSubscription = timer(30000).subscribe(() => {
          this.clearIncidentNotification();
        });
  }

  /* get the current page URL */
  getUrl() {
    this.url = this.router.url;
    this.oUrl = this.url.split('/')[1];

    if (this.url !== '/auth/login') {
      // this.role = this.authService.getLoggedUserRole();
      // this.isOperator = this.role !== 'operator' ? false : true;
      this.clearIncidentNotification();
      this.getUserInfo();

    }
  }
  
  alertBox(incidents:any){
    this._snackBar.open(incidents,"",{duration:3000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,panelClass:['notification-snackbar']});
  }
  clearIncidentNotification(){
    this.apiService.removeNotification().subscribe((res:any)=>{
      this.startIncidentNotification();
    })
  }
  startIncidentNotification(){
      this.apiService.getNotification().subscribe((res:any)=>{
          let response = res;
          let totalValues:any = 0;
          for(let key in response){
              totalValues = key;
          }
          if(totalValues != 0){
            this.alertBox(`New incidents occured - ${totalValues}`);
            this.bellNotification = totalValues;
          }
          else{
            this.bellNotification = '';
          }
          this.subscribeToData();
      });
  }
  bellNotificationClick(){
    this.router.navigate(['safety-incidents/details/0']);
  }
  getUserInfo() {
    this.userinfo = this.authService.getLoggedUserInfo();
    this.username = this.userinfo.first_name;
    this.userrole = helper.decodeToken(JSON.parse(localStorage.getItem('user'))['token'])['role'];
  }

  /* Method to logout */
  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    this.router.navigate(['account/login']);
  }

  isUserAuthenticated() {
    return this.authService.isAuthenticated();
  }

  isAuthenticated(screen, action) {
    return this.utils.isPageAuthenticated(screen, action);
}

  toggleDatePicker() {
    this.isDatePickerOpened = !this.isDatePickerOpened;
  }

  GoToCompliancePage() {
    this.router.navigate(['safety-incidents'])
  }

  GoToProximityPage() {
    this.router.navigate(['distance-incidents']);
  }

  GoToCognitivePage() {
    this.router.navigate(['cognitive-incidents']);
  }
  goToAsset() {
    this.router.navigate(['manage-asset']);
  }
  goToDriver() {
    this.router.navigate(['manage-driver']);
  }
  goToZone() {
    //  this.router.navigate(['manage-zone']);
    this.router.navigate(['manage-zone-site']);
  }

  goToWorkArea() {
    this.router.navigate(['manage-workarea']);
  }

  goToVehicle() {
    this.router.navigate(['manage-vehicle']);
  }

  goToDevice() {
    this.router.navigate(['manage-device']);
  }

  goToUser() {
    this.router.navigate(['manage-user']);
  }

  goToFeature(navItem) {

    if (navItem.name !== 'Management') {
      this.router.navigate([navItem.route])
    }
  }

}
